<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="920px">
            <v-card class="elevation-1">
                <ValidationObserver ref="refDepartamento" v-slot="{ invalid, validated }">
                    <v-form name="form" @submit.prevent="store">
                        <v-toolbar tile dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Nuevo departamento</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="danger" text type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense large>fas fa-save</v-icon>Guardar departamento</v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                            <v-text-field label="Departamento*" v-model="department.departamento" prepend-icon="fas fa-building"
                                            :error-messages="errors" :success="valid" required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import DepartamentoDataService from '@/service/catalogo/departamento/departamentoDataService';
export default {
    name: 'Nuevo',
    props: {
        dialog: { type: Boolean, default: false },
        handleModal: { type: Function },
        initialize: { type: Function }
    },
    data(){
        return {
            department: {
                departamento: '',
                clave: ''
            }
        }
    },
    methods: {
        store(){
            this.$refs.refDepartamento.validate().then( isValid => {
                if( isValid ){
                    DepartamentoDataService.store(this.department).then(_ => {
                        this.handleModal();
                        this.initialize();
                        this.$refs.refDepartamento.reset();
                        this.$swal.fire({ icon: 'success', title: 'Departamento guardado', showConfirmButton: false, timer: 1500 });
                    }).catch(err => {
                        this.$swal.fire({ icon: 'error', html: err.response.data, title: 'Error al guardar el departamento', showConfirmButton: false, timer: 1500 });
                    });
                }
            })
        }
    },
}
</script>