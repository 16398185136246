<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="920px">
            <v-card class="elevation-1">
                <ValidationObserver ref="refDepartamentEdit" v-slot="{ invalid, validated }" :immediate="true">
                    <v-form name="form" @submit.prevent="update">
                        <v-toolbar tile dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Editar departamento</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="danger" text type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense large>fas fa-save</v-icon>Actualizar departamento</v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                            <v-text-field label="Departamento*" v-model="department.departamento" prepend-icon="fas fa-building"
                                            :error-messages="errors" :success="valid" required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import DepartamentoDataService from '@/service/catalogo/departamento/departamentoDataService';
export default {
    name: 'Editar',
    props: {
        dialog: { type: Boolean, default: false },
        handleModal: { type: Function },
        initialize: { type: Function },
        department: { type: Object }
    },
    methods: {
        update(){
            this.$refs.refDepartamentEdit.validate().then( isValid => {
                if( isValid ){
                    DepartamentoDataService.update(this.department).then(_ => {
                        this.handleModal();
                        this.initialize();
                        this.$refs.refDepartamentEdit.reset();
                        this.$swal.fire({ icon: 'success', title: "Departamento actualizado", showConfirmButton: false, timer: 1500 });
                    }).catch(err => {
                        this.$swal.fire({ icon: 'error', html: err.response.data, title: 'Error al actualizar el departamento', showConfirmButton: false, timer: 1500 });
                    });

                }
            })
        }
    },
}
</script>