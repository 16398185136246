<template>
    <v-col cols="12" sm="12" md="12">
        <v-card class="elevation-4">
            <v-card-title>
                <v-toolbar flat>
                    <v-btn small dark color="primary" @click="dialog = true">
                        <v-icon left>fas fa-plus-square</v-icon>Nuevo departamento
                    </v-btn>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-col cols="12" md="8" sm="12" class="d-flex justify-end">
                    <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar departamento" single-inline hide-details @keydown.enter="searchDepartamento">
                    </v-text-field>
                    <v-btn icon class="mt-4 ml-2">
                        <v-icon dense>fas fa-sync</v-icon>
                    </v-btn>
                </v-col>
                <Columns :headers.sync="headers"></Columns>
            </v-card-title>
            <v-data-table dense :headers="filteredHeaders" :items="departamentos" class="elevation-1"
            :loading="loading" :options.sync="options"
            :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
            :server-items-length="totalDepartments">
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="show(item)" v-if="!item.eliminado">fas fa-edit</v-icon>
                    <v-icon small class="mr-2" @click="removeDepartamento(item)" v-if="!item.eliminado">fas fa-trash-alt</v-icon>
                </template>
                <template v-slot:[`item.eliminado`]="{ item }">
                    <v-chip small :color="colorstatus(item.eliminado)" label x-small dark
                    v-text="!item.eliminado ? 'Activo' : 'Inactivo'">
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>
        <NuevoDepartamento :dialog.sync="dialog" :initialize.sync="initTableDepartment"
        :handleModal="handleModal" v-if="dialog"/>

        <EditarDepartamento :dialog.sync="editDialog" :department.sync="getInfo" :initialize.sync="initTableDepartment"
        :handleModal="handleEditModal" v-if="editDialog"/>
    </v-col>
</template>
<script>
import DepartamentoDataService from '@/service/catalogo/departamento/departamentoDataService';
import NuevoDepartamento from './Nuevo.vue';
import EditarDepartamento from './Editar.vue';
import { getParamsPagination } from '@/utils/pagination';
import Columns from '@/components/shared/Columns.vue';
export default {
    name: 'ListaDepartamento',
    components : { NuevoDepartamento, EditarDepartamento, Columns },
    created(){
        this.initTableDepartment();
    },
    data(){
        return {
            headers: [
                { text: 'Acciones', value: 'actions', sortable: false, groupable: false, show: true},
                { text: 'ID', align :' d-none', sortable: false, value: 'id' , visible: false, groupable: false, show: false},
                // { text: 'Clave', value: 'clave', sortable: false, groupable: false, show: true },
                { text: 'Departamento', value: 'departamento', sortable: false, groupable: false, show: true },
                { text: 'Estatus', value: 'eliminado', sortable: false, groupable: false, show: true },
            ],
            departamentos: [],
            options: {},
            totalDepartments: 0,
            loading: false,
            search: '',
            dialog: false,
            editDialog: false,
            getInfo: []
        }
    },
    methods: {
        searchDepartamento(){
            this.options.page = 1;
            this.initTableDepartment(this.options);
        },
        initTableDepartment( options = {} ){
            this.loading = true;
            const params = getParamsPagination({ options, search: this.search });
            DepartamentoDataService.table( params ).then( resp => {
                const { rows, totalElements } = resp.data;
                this.departamentos = rows;
                this.totalDepartments = totalElements;
            }).catch( err => {
                this.$swal.fire({icon: 'error', text: err, title: 'Error al obtener los departamentos', showConfirmButton: false, timer: 1500 });
            }).finally(() => {
                this.loading = false;
            })
        },
        show( item ){
            this.getInfo = item;
            this.editDialog = true;
        },
        removeDepartamento( item ){
            this.$swal.fire({
                title: '¿Estas seguro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminarlo!'
            }).then((result) => {
                if (result.value) {
                    DepartamentoDataService.delete( item.id ).then( resp => {
                        if( resp.status == 200 ){
                            this.$swal.fire({ icon: 'success', text: 'Departamento eliminado', title: 'Eliminando departamento', showConfirmButton: false, timer: 1500 });
                            this.initTableDepartment();
                        }
                    }).catch( err => {
                        this.$swal.fire({ icon: 'error', text: err, title: 'Error al eliminar el departamento', showConfirmButton: false, timer: 1500 });
                    });
                }
            }).catch( () => {
                this.$swal.fire({ icon: 'error', text: 'Proceso, cancelado', title: 'Error al eliminar el departamento', showConfirmButton: false, timer: 1500 });
            });
        },
        colorstatus( status ){ return status ? 'red' : 'green'; },
        handleModal(){ this.dialog = !this.dialog; },
        handleEditModal(){ this.editDialog = !this.editDialog; }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableDepartment(val);
                }
            }, deep: true
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter( header => header.show ); }
    }
}
</script>