import { http, authHeader } from '@/http-commons';

class DepartamentoDataService {
    getallDepartamento(){
        return http.get('api/v1/departamento/getAll', { headers : authHeader() });
    }
    store( departamento ){
        return http.post('api/v1/departamento/create', departamento, { headers : authHeader() });
    }
    show( departmentId ){
        return http.get(`api/v1/departamento/show/${ departmentId }`, { headers : authHeader() });
    }
    update( departamento ){
        return http.put(`api/v1/departamento/update/${ departamento.id }`, departamento, { headers : authHeader() });
    }
    delete( departmentId ){
        return http.delete(`api/v1/departamento/delete/${ departmentId }`, { headers : authHeader() });
    }

    table( params ){
        return http.get('api/v1/departamento/table', { params , headers : authHeader() });
    }
    
}
export default new DepartamentoDataService();